const validate = require("jquery-validation")
const Swal = require('sweetalert2')

$(document).ready(function() {
    SendContactForm()
})

let SendContactForm = () => {
    let cForm = $('#email-form')
    if (cForm.length > 0) {        
        cForm.validate({
            rules: {
                voorname: {
                    required: true,
                },
                achternaam: {
                    required: true,
                },
                emailadres: {
                    required: true,
                    email: true
                }
            },
            errorPlacement: function(error, element) {
                return true;
            },
            submitHandler: function(form) {
                let formData = cForm.serialize();
                $('.success-message.w-form-done').hide()
                $('.error-message.w-form-fail').hide()

                $.ajax({
                    type: 'POST',
                    url: 'aa36286437f51dd17f9771375e9a9400.php',
                    dataType: 'json',
                    data: formData
                }).done(function(response) {
                    if (response.success === true) {
                        cForm.trigger('reset')
                        $('.w-form-done').show()
                    } else {
                        $('.w-form-fail').show()
                    }
                });
                return false;
            }
        });
    }
}